import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container className="self-center">
      <h3>Mitt Skolval</h3>
      <p>
        Mitt Skolval är ett företag som leds av några av Sveriges ledande
        skolvalsforskare. I samband med vår forskning såg vi flera problem som
        skolaktörer hade i samband med sitt skolval, vilket är bakgrunden till
        att vi grundade Mitt Skolval. Detta är inte konstigt – att lösa
        matchningsproblemet mellan elever och skolor är svårt. Forskningsfältet
        är komplext och rättsläget är svårorienterat.
      </p>
      <p>
        Vi har nu hjälpt både stora och små kommuner, samt fristående skolor,
        med att hantera, administrera och utveckla skolvalet sedan 2017. Vi
        arbetar idag med ett femtiotal kommuner. Vår målsättning är att skolvalet ska
        vara enkelt, effektivt, transparent och rättssäkert.
      </p>
    </Container>
  </Layout>
);

export default IndexPage;
